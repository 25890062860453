import React, { useState, useCallback, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const DropdownMenu = React.memo(({ title, items, mainLink }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200);
  }, []);

  return (
    <NavItem onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <NavLink to={mainLink}>{title}</NavLink>
      {isOpen && (
        <DropdownContainer role="menu">
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                <Link to={item.link}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </DropdownContainer>
      )}
    </NavItem>
  );
});

const NavItem = styled.li`
  position: relative;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const NavLink = styled(Link)`
  color: #000;
  text-decoration: none;
  padding: 10px 20px;
  font-weight: 700;
  position: relative;

  &:hover {
    border-bottom: 3px solid #00aeef;
  }

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  top: calc(100% + 3px);
  left: 0;
  min-width: 250px;
  border-radius: 4px;
  overflow: hidden;

  ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;

    li {
      padding: 10px 20px;
      white-space: nowrap;

      a {
        color: #000;
        text-decoration: none;

        &:hover {
          color: #00aeef;
        }
      }
    }
  }
`;

export default DropdownMenu;
