import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
`;

const Title = styled.h2`
  margin-bottom: 10px;
  color: #333;
`;

const Description = styled.p`
  margin-bottom: 20px;
  color: #555;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.a`
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: ${(props) => props.bgColor || "#007bff"};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor ||
      (props.bgColor === "#007bff" ? "#0056b3" : "#ff5700")};
  }
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff6f00;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #ff5700;
  }
`;

const BeerCardModal = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <Title>2025 Craft Brew Tour Cards</Title>
        <Description>
          Get your 2025 Craft Brew Tour Card and enjoy free drinks at 34
          breweries, distilleries, and bars across Western New York! All for
          just $40.
        </Description>
        <ButtonGroup>
          <Button
            href="https://habitatbuffalo.betterworld.org/events/2025-craft-brew-tour-cards"
            target="_blank"
            rel="noopener noreferrer"
            bgColor="#007bff"
            hoverColor="#0056b3"
          >
            Buy Now
          </Button>
        </ButtonGroup>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default BeerCardModal;
