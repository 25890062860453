import React from "react";
import styled from "styled-components";

const quotes = [
  {
    quote:
      "I am so grateful for the support and help provided by Habitat Buffalo. They have made a significant impact on our community.",
    author: "Jane, Volunteer",
  },
  {
    quote:
      "Volunteering here is the best decision I've made. The sense of fulfillment and the friendships I've formed are invaluable.",
    author: "Sam, Volunteer",
  },
  {
    quote:
      "A wonderful community and great mission. Habitat Buffalo truly cares about improving the lives of those in need.",
    author: "Lisa, Community Partner",
  },
  {
    quote:
      "Building homes and hope together. It's inspiring to see how many lives we can touch through our collective efforts.",
    author: "Daniel, Staff Member",
  },

  {
    quote:
      "Amazing organization with amazing people. Habitat Buffalo is making a real difference in our community.",
    author: "Christopher, Staff Member",
  },
  {
    quote:
      "Helping others build their dreams has been an incredibly rewarding experience. I encourage everyone to get involved.",
    author: "Anna, Volunteer",
  },
  {
    quote:
      "A community that cares. Habitat Buffalo's commitment to helping those in need is truly inspiring.",
    author: "James, Community Partner",
  },
  {
    quote:
      "Together we build a better future. The impact of our work extends far beyond just building houses.",
    author: "Jim, Staff Member",
  },
  {
    quote:
      "Every volunteer makes a difference. It's amazing to see the direct impact of our efforts on the families we help.",
    author: "Brian, Volunteer",
  },
  {
    quote:
      "Creating homes and changing lives. The work we do at Habitat Buffalo has a profound impact on the community.",
    author: "DJ, Staff Member",
  },
  {
    quote:
      "Habitat for Humanity builds more than homes. They build communities and create opportunities for a better future.",
    author: "Elizabeth, Volunteer",
  },
];

const getRandomQuote = () => {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
};

const Testimonial = () => {
  const { quote, author } = getRandomQuote();

  return (
    <TestimonialContainer>
      <QuoteMark>“</QuoteMark>
      <Quote>{quote}</Quote>
      <Author>- {author}</Author>
    </TestimonialContainer>
  );
};

const TestimonialContainer = styled.div`
  text-align: center;
  margin: 50px 20px;
  padding: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const QuoteMark = styled.div`
  font-size: 6em;
  color: #00aeef;
  margin-bottom: -50px;
`;

const Quote = styled.p`
  font-size: 1.5em;
  font-style: italic;
  margin: 20px 0;
`;

const Author = styled.p`
  font-size: 1em;
  font-weight: bold;
`;

export default Testimonial;
