import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled components
const FormContainer = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: var(--hhb-white);
  border-radius: 8px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: var(--font-size-body);
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  padding: 12px;
  font-size: var(--font-size-body);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const Textarea = styled.textarea`
  padding: 12px;
  font-size: var(--font-size-body);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  resize: vertical;
  height: 100px;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const SubmitButton = styled.button`
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 12px;
  font-size: var(--font-size-body);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: var(--accent-color);
  }
`;

const ThankYouMessage = styled.div`
  text-align: center;
  padding: 20px;
  background-color: var(--hhb-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FaithForm = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    organization: "",
    interest: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://habitat-buffalo-4b593fbcd470.herokuapp.com/submit-faith", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formState),
    })
      .then((response) => {
        if (response.ok) {
          setFormSubmitted(true);
        } else {
          throw new Error("Form submission failed");
        }
      })
      .catch((error) => {
        alert("There was an error submitting the form.");
      });
  };

  if (formSubmitted) {
    return (
      <ThankYouMessage>
        <h2>Thank You!</h2>
        <p>
          Your submission has been received. We will get back to you shortly.
        </p>
        <Link to="/" className="button">
          Return to Home
        </Link>
      </ThankYouMessage>
    );
  }

  return (
    <FormContainer>
      <StyledForm onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Name:</Label>
          <Input
            type="text"
            name="name"
            value={formState.name}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Email:</Label>
          <Input
            type="email"
            name="email"
            value={formState.email}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Phone:</Label>
          <Input
            type="tel"
            name="phone"
            value={formState.phone}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Organization:</Label>
          <Input
            type="text"
            name="organization"
            value={formState.organization}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>How would you like to get involved?</Label>
          <Input
            type="text"
            name="interest"
            value={formState.interest}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Message:</Label>
          <Textarea
            name="message"
            value={formState.message}
            onChange={handleInputChange}
          ></Textarea>
        </FormGroup>

        <SubmitButton type="submit">Submit</SubmitButton>
      </StyledForm>
    </FormContainer>
  );
};

export default FaithForm;
