import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled components (same as your current setup)
const FormContainer = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: var(--hhb-white);
  border-radius: 8px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: var(--font-size-body);
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  padding: 12px;
  font-size: var(--font-size-body);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const Select = styled.select`
  padding: 12px;
  font-size: var(--font-size-body);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const Textarea = styled.textarea`
  padding: 12px;
  font-size: var(--font-size-body);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  resize: vertical;
  height: 100px;

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }
`;

const SubmitButton = styled.button`
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 12px;
  font-size: var(--font-size-body);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: var(--accent-color);
  }
`;

const ThankYouMessage = styled.div`
  text-align: center;
  padding: 20px;
  background-color: var(--hhb-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const HomeRepairForm = () => {
  const [formState, setFormState] = useState({
    isOwner: "",
    isPrimaryResidence: "",
    isSingleFamilyHome: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    address: "",
    city: "",
    zip: "",
    isVeteran: "",
    isDisabled: "",
    gender: "",
    ethnicity: "",
    rural: "",
    income: "",
    householdSize: "",
    repairDescription: "",
  });

  const [showFullForm, setShowFullForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handlePreliminaryChange = (e) => {
    const { name, value } = e.target;
    const updatedFormState = { ...formState, [name]: value };

    setFormState(updatedFormState);

    if (
      updatedFormState.isOwner === "Yes" &&
      updatedFormState.isPrimaryResidence === "Yes" &&
      updatedFormState.isSingleFamilyHome === "Yes"
    ) {
      setShowFullForm(true);
      setShowMessage(false);
    } else {
      setShowFullForm(false);
      setShowMessage(
        updatedFormState.isOwner === "No" ||
          updatedFormState.isPrimaryResidence === "No" ||
          updatedFormState.isSingleFamilyHome === "No"
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://habitat-buffalo-4b593fbcd470.herokuapp.com/submit-repair-program",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formState),
        }
      );

      if (response.ok) {
        setFormSubmitted(true);
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      alert("There was a problem submitting the form. Please try again.");
    }
  };

  if (formSubmitted) {
    return (
      <ThankYouMessage>
        <h2>Thank You!</h2>
        <p>
          Your submission has been received. We will get back to you shortly.
        </p>
        <Link to="/" className="button">
          Return to Home
        </Link>
      </ThankYouMessage>
    );
  }

  return (
    <FormContainer>
      <StyledForm onSubmit={handleSubmit}>
        {/* Preliminary Questions */}
        <FormGroup>
          <Label>Are you the current owner of this home?</Label>
          <Select
            name="isOwner"
            value={formState.isOwner}
            onChange={handlePreliminaryChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Is this your primary residence?</Label>
          <Select
            name="isPrimaryResidence"
            value={formState.isPrimaryResidence}
            onChange={handlePreliminaryChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Is this a single-family home?</Label>
          <Select
            name="isSingleFamilyHome"
            value={formState.isSingleFamilyHome}
            onChange={handlePreliminaryChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </FormGroup>

        {showMessage && (
          <ThankYouMessage>
            <p>
              Thank you for your interest. Unfortunately, we are currently
              unable to assist with this type of request.
            </p>
          </ThankYouMessage>
        )}

        {/* Full Form */}
        {showFullForm && (
          <>
            <FormGroup>
              <Label>First Name:</Label>
              <Input
                type="text"
                name="firstName"
                value={formState.firstName}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Last Name:</Label>
              <Input
                type="text"
                name="lastName"
                value={formState.lastName}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Phone:</Label>
              <Input
                type="tel"
                name="phone"
                value={formState.phone}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Email:</Label>
              <Input
                type="email"
                name="email"
                value={formState.email}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Date of Birth:</Label>
              <Input
                type="date"
                name="dob"
                value={formState.dob}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Address:</Label>
              <Input
                type="text"
                name="address"
                value={formState.address}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>City:</Label>
              <Input
                type="text"
                name="city"
                value={formState.city}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>ZIP Code:</Label>
              <Input
                type="text"
                name="zip"
                value={formState.zip}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Are you a veteran in good standing?</Label>
              <Select
                name="isVeteran"
                value={formState.isVeteran}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>Do you identify as a person with a disability?</Label>
              <Select
                name="isDisabled"
                value={formState.isDisabled}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>Gender:</Label>
              <Select
                name="gender"
                value={formState.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Non-binary">Non-binary</option>
                <option value="Other">Other</option>
                <option value="Prefer not to say">Prefer not to say</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>Ethnicity/Race:</Label>
              <Select
                name="ethnicity"
                value={formState.ethnicity}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="American Indian or Alaska Native">
                  American Indian or Alaska Native
                </option>
                <option value="Asian">Asian</option>
                <option value="Black or African American">
                  Black or African American
                </option>
                <option value="Hispanic or Latino">Hispanic or Latino</option>
                <option value="Native Hawaiian or Other Pacific Islander">
                  Native Hawaiian or Other Pacific Islander
                </option>
                <option value="White">White</option>
                <option value="Two or more races">Two or more races</option>
                <option value="Other">Other</option>
                <option value="Prefer not to say">Prefer not to say</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>Is your home located in a rural area?</Label>
              <Select
                name="rural"
                value={formState.rural}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>Annual Household Income:</Label>
              <Input
                type="number"
                name="income"
                value={formState.income}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Household Size:</Label>
              <Input
                type="number"
                name="householdSize"
                value={formState.householdSize}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Repair Description:</Label>
              <Textarea
                name="repairDescription"
                value={formState.repairDescription}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <SubmitButton type="submit">Submit</SubmitButton>
          </>
        )}
      </StyledForm>
    </FormContainer>
  );
};

export default HomeRepairForm;
