import React from "react";
import styled, { keyframes } from "styled-components";

// Define the spin animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Container for the loading spinner
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

// The spinning wheel
const Spinner = styled.div`
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid var(--hhb-primary); /* Habitat primary color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

const Loading = () => (
  <LoadingContainer role="status" aria-label="Loading">
    <Spinner />
  </LoadingContainer>
);

export default Loading;
