import ContactForm from "./ContactForm";
import ContractorForm from "./ContractorForm";
import DonationForm from "./DonationForm";
import FaithForm from "./FaithForm";
import FundraiserForm from "./FundraiserForm";
import GivingForm from "./GivingForm";
import MediaContactForm from "./MediaContactForm";
import HomeRepairForm from "./HomeRepairForm";
import SponsorshipRequestForm from "./SponsorshipRequestForm";
import TeamBuildForm from "./TeamBuildForm";
import WinterWarriorsForm from "./WinterWarriorsForm";
import WorkplaceGivingForm from "./WorkplaceGivingForm";
import YouthProgramForm from "./YouthProgramForm";

export const formComponents = {
  contact: ContactForm,
  contractor: ContractorForm,
  donation: DonationForm,
  faith: FaithForm,
  fundraiser: FundraiserForm,
  giving: GivingForm,
  media: MediaContactForm,
  repair: HomeRepairForm,
  sponsorship: SponsorshipRequestForm,
  teamBuild: TeamBuildForm,
  winterWarrior: WinterWarriorsForm,
  workplaceGiving: WorkplaceGivingForm,
  youthProgram: YouthProgramForm,
};
