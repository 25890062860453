import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import GlobalStyle from "../globalStyles";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Testimonial from "../components/Testimonial";
import CallToAction from "../components/CallToAction";
import cta1 from "../assets/images/cta/1.webp";
import cta2 from "../assets/images/cta/2.webp";
import cta3 from "../assets/images/cta/3.webp";
import cta4 from "../assets/images/cta/4.webp";
import cta5 from "../assets/images/restore/restore-hero.webp";
import cta6 from "../assets/images/cta/6.webp";

const Layout = ({ children }) => {
  const location = useLocation();

  const routeTitles = {
    "/who-we-are": "Who We Are",
    "/how-to-apply": "How to Apply",
    "/get-involved": "Get Involved",
    "/support-us": "Support Us",
    "/restore": "ReStore",
    "/donate": "Donate",
  };

  const heroImages = {
    "/who-we-are": cta1,
    "/how-to-apply": cta2,
    "/get-involved": cta3,
    "/support-us": cta4,
    "/restore": cta5,
    "/donate": cta6,
  };

  const topLevelPaths = Object.keys(routeTitles);

  const shouldDisplayBanner = topLevelPaths.includes(location.pathname);
  const pageTitle = routeTitles[location.pathname];
  const heroImage = heroImages[location.pathname];

  return (
    <>
      <GlobalStyle />
      <Navbar />
      {shouldDisplayBanner && (
        <Hero backgroundImage={heroImage}>
          <HeroTitle>{pageTitle}</HeroTitle>
        </Hero>
      )}
      <main>{children}</main>
      <Testimonial
        quote="I love the idea of working for a greater cause. It makes it all worthwhile. Not just for my family and I, but for so many more!"
        author="Tara Young, Habitat Buffalo Homeowner"
      />
      <CallToAction
        title="Home Starts With You"
        description="Join us in Building a Better Buffalo. We need your support to create more access to affordable housing."
        buttonText="DONATE NOW"
        buttonLink="/donate"
        backgroundImage={cta1}
        overlayColor="rgba(0, 0, 0, 0.5)"
        textColor="#000"
        buttonColor="#f36f25"
        buttonHoverColor="#e05c1b"
      />
      <Footer />
    </>
  );
};

const Hero = styled.div`
  background: url(${(props) => props.backgroundImage}) no-repeat center center;
  background-size: cover;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroTitle = styled.h1`
  color: white;
  font-size: 2.5em;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
`;

export default Layout;
