// components/PageTransition.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const PageTransition = ({ children }) => {
  const [displayChildren, setDisplayChildren] = useState(children);
  const [transitionStage, setTransitionStage] = useState("fadeIn");
  const location = useLocation();

  useEffect(() => {
    setTransitionStage("fadeOut");
  }, [location.pathname]);

  useEffect(() => {
    if (transitionStage === "fadeOut") {
      const timeout = setTimeout(() => {
        setDisplayChildren(children);
        setTransitionStage("fadeIn");
      }, 300); // Duration of fade-out animation

      return () => clearTimeout(timeout); // Clear timeout if component unmounts
    }
  }, [transitionStage, children]);

  useEffect(() => {
    if (transitionStage === "fadeOut") {
      setDisplayChildren(null);
    }
  }, [transitionStage]);

  return <Wrapper className={transitionStage}>{displayChildren}</Wrapper>;
};

const Wrapper = styled.div`
  &.fadeIn {
    animation: fadeIn 0.5s forwards;
  }
  &.fadeOut {
    animation: fadeOut 0.3s forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export default PageTransition;
