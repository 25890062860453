import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";
import cta1 from "../assets/images/cta/1.webp";
import cta2 from "../assets/images/cta/2.webp";

const callToActions = [
  {
    title: "Home Starts With You",
    description:
      "Your donation helps Habitat Buffalo provide safe, affordable housing to families in need. Every dollar counts!",
    buttonText: "DONATE",
    buttonLink: "/donate",
    backgroundImage: cta1,
  },
  {
    title: "Build A Better Future",
    description:
      "Support Habitat Buffalo and help us build homes for those in need. Your contribution makes a real difference!",
    buttonText: "GIVE NOW",
    buttonLink: "/donate",
    backgroundImage: cta2,
  },
];

const getRandomCallToAction = () =>
  callToActions[Math.floor(Math.random() * callToActions.length)];

const CallToAction = ({
  overlayColor = "rgba(0, 0, 0, 0.5)",
  textColor = "black",
  buttonColor = "#f36f25",
  buttonHoverColor = "#e05c1b",
  minHeight = "300px",
}) => {
  const [callToAction, setCallToAction] = useState(getRandomCallToAction);

  const location = useLocation();

  useEffect(() => {
    setCallToAction(getRandomCallToAction());
  }, [location]);

  return (
    <LazyLoad height={600} offset={100}>
      <Section
        backgroundImage={callToAction.backgroundImage}
        minHeight={minHeight}
      >
        <Overlay overlayColor={overlayColor}>
          <Content textColor={textColor}>
            <Title>{callToAction.title}</Title>
            <Description>{callToAction.description}</Description>
            <Button
              href={callToAction.buttonLink}
              buttonColor={buttonColor}
              buttonHoverColor={buttonHoverColor}
            >
              {callToAction.buttonText}
            </Button>
          </Content>
        </Overlay>
      </Section>
    </LazyLoad>
  );
};

const Section = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background: url(${(props) => props.backgroundImage}) no-repeat center center;
  background-size: cover;
  min-height: ${(props) => props.minHeight || "300px"};

  @media (max-width: 768px) {
    padding: 30px 15px;
  }

  @media (max-width: 480px) {
    padding: 20px 10px;
    min-height: 250px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.overlayColor || "rgba(0, 0, 0, 0.5)"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  text-align: center;
  max-width: 600px;
  color: ${(props) => props.textColor || "black"};
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 500px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    max-width: 100%;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 28px;
  color: var(--hhb-primary);

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  margin-bottom: 30px;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 25px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const Button = styled.a`
  background-color: ${(props) => props.buttonColor || "#f36f25"};
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 12px;
  }

  &:hover {
    background-color: ${(props) => props.buttonHoverColor || "#e05c1b"};
  }
`;

export default CallToAction;
